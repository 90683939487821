<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import { awards } from "@/config/api/awards";
import { users } from "@/config/api/users";
import AwardUserModal from "@/components/awards/award-modal";
import getFileType from "@/utils/getFileType";
/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AwardUserModal,
  },
  data() {
    return {
      usersData: [],
      getFileType: getFileType,

      title: "Award Info",
      awardInfo: null,
      selectedAward: null,
      selectedUser: null,
      userList: [],
      loading: false,
      items: [
        {
          text: "Users Awards",
          to: { name: "Users Awards" },
        },
        {
          text: "Award Info",
          active: true,
        },
      ],
    };
  },
  async created() {
    this.loading = true;
    if (this.$route.query.id) {
      await this.loadInfo();
      await this.loadUserData();
      this.loading = false;
    }
  },
  computed: {},
  methods: {
    async loadUserData() {
      try {
        const api = users.getAll;

        api.params = { limit: 9999999, usersOnly: true, isCompany: false };
        let res = await this.generateAPI(api);
        res.data.users.docs.forEach((element) => {
          this.usersData.push({
            label: element.name ? element.name : `no name - id(${element._id})`,
            value: element._id,
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    async loadInfo() {
      if (this.$route.query.id) {
        try {
          const api = awards.users.getById;
          api.id = this.$route.query.id;
          let res = await this.generateAPI(api);
          this.awardInfo = res.data.model;
        } catch (error) {
          console.error(error);
        }
      }
    },
    showUserView(val) {
      this.selectedUser = val;
      this.$router.push({
        path: "/user-info",
        query: { id: val._id },
      });
    },
    showUserActionModal(data) {
      this.selectedAward = data;
      this.$bvModal.show("action-User");
    },
    showActionModal() {
      this.$bvModal.show("action-Award");
    },
    hideActionModal() {
      this.$bvModal.hide("action-Award");
    },
    editAward(data) {
      this.selectedAward = data;
      this.showActionModal();
    },
    deleteAward(data) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure, you want to delete this award?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.loading = true;
            const api = awards.users.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Award deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
              })
              .catch((err) => {
                this.$bvToast.toast("Award is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err);
                this.loadInfo();
              })
              .finally(async () => {
                this.loading = false;
              });
          }
        });
    },
  },
  watch: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading">
      <div style="text-align: center" class="mt-5 mb-5">
        <pulse-loader color="#505d69" :loading="loading"></pulse-loader>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div style="text-align: end; float-right" class="mb-2 mb-2">
              <b-button
                class="mr-1"
                type="button"
                @click="deleteAward(awardInfo)"
                pill
                variant="danger"
              >
                Delete
              </b-button>
              <b-button @click="editAward(awardInfo)" pill variant="primary">
                Edit
              </b-button>
            </div>

            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail" :v-if="awardInfo">
                  <div class="row">
                    <div class="col-md-11 col-9">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="product-1"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              id="expandedImg"
                              src="@/assets/award.png"
                              alt
                              class="img-fluid mx-auto d-block"
                              height="200"
                              width="200"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="mt-4 mt-xl-3">
                  <h5 class="mt-1 mb-3">{{ awardInfo.name }}</h5>
                  <h5 class="mt-1 mb-3">
                    {{ moment(awardInfo.date_received).format("LLL") }}
                  </h5>

                  <hr class="my-4" />
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="font-size-14">Basic Info :</h5>
                      <ul class="list-unstyled product-desc-list">
                        <li>
                          Description:
                          {{ awardInfo.description }}
                        </li>
                        <li>
                          Status:
                          {{ awardInfo.deletedAt ? "Inactive" : "Active" }}
                        </li>
                        <li v-if="awardInfo.is_verified" class="verified_icon">
                          Verified:
                          <i
                            style="color: green; font-size: 1.2rem"
                            class="ri-checkbox-circle-fill"
                          ></i>
                        </li>
                        <li v-if="!awardInfo.is_verified" class="verified_icon">
                          Verified:
                          <i
                            class="ri-close-circle-line verified_icon"
                            style="color: red; font-size: 1.2rem"
                          ></i>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-6 d-flex flex-column align-items-end">
                      <div style="width: fit-content">
                        <div style="text-align: center">
                          <h5 class="font-size-14">Award Document :</h5>
                        </div>
                        <ul
                          class="list-unstyled product-desc-list"
                          style="width: fit-content"
                        >
                          <li>
                            <b-container fluid>
                              <a
                                :href="awardInfo.award_document"
                                target="_blank"
                              >
                                <b-img
                                  thumbnail
                                  fluid
                                  v-if="
                                    getFileType(awardInfo.award_document) ==
                                      'png' ||
                                    getFileType(awardInfo.award_document) ==
                                      'jpeg' ||
                                    getFileType(awardInfo.award_document) ==
                                      'jpg'
                                  "
                                  :src="awardInfo.award_document"
                                  alt="Award Document"
                                  width="100"
                                ></b-img>
                              </a>
                            </b-container>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- end row -->

            <div class="mt-4">
              <h5 class="font-size-14 mb-3">Awarded User Info:</h5>
              <div style="text-align: end; float-right"></div>
              <div class="product-desc">
                <router-link
                  :to="{
                    path: `/user-info`,
                    query: { id: awardInfo.user_id._id },
                  }"
                >
                  <div style="width: fit-content">
                    <div style="text-align: center">
                      {{ awardInfo.user_id.name }}
                    </div>
                    <img
                      thumbnail
                      fluid
                      src="@/assets/mawj/logo.svg"
                      alt="Awarded User"
                      width="100"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->

    <!-- end row -->
    <AwardUserModal
      :selectedAward="selectedAward"
      :awardData="usersData"
      v-on:resetModal="selectedAward = null"
      v-on:reloadData="loadInfo"
      v-on:closeModal="hideActionModal"
      type="user"
    />
  </Layout>
</template>

<style scoped>
.verified_icon {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
</style>
